import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getImportLoading, getImportValidationData, getRemoveLoading } from './templates-selector';
import { removeTemplate } from './templates-actions';
import { getExportLoading } from '../header/header-reducer';
import { getWriteTabsPermissions } from '../../store/user/user-reducer';
import { useLocation } from 'react-router-dom';
import { ExportImportButton, ExportImportButtonText } from '@med-fe/ui';
import { UploadModal } from './UploadModal';
import { ValidationModal } from './ValidationModal';

const { Text } = Typography;

export const TemplatesActions = ({ selectedKeys, data, clearSelectedRows }) => {
  const deleteConfirmText = 'Please select template(s) to delete.';
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [isAllSelectedAssigned, setIsAllSelectedAssigned] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(deleteConfirmText);
  const dispatch = useDispatch();
  const removeLoading = useSelector(getRemoveLoading);
  const importValidationData = useSelector(getImportValidationData);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [assignTemplateUpload, setAssignTemplateUpload] = useState<boolean>(false);
  const [templateMaintenanceUpload, setTemplateMaintenanceUpload] = useState<boolean>(false);
  const [type, setType] = useState('');
  const [tableId, setTableId] = useState('');

  const removeItems = () => {
    let message: any = deleteConfirmText;
    const assignedTemplates = selectedKeys
      .map((item) => data.find(({ key }) => item === key))
      .filter(({ template }) => template.assigned);

    if (assignedTemplates.length) {
      const templateNames = assignedTemplates.map(({ template }) => template.templateName);
      message = (
        <>
          <p>
            <span>
              {assignedTemplates.length === selectedKeys.length
                ? 'All selected templates are assigned:'
                : 'The next templates are assigned:'}
            </span>
            {templateNames.map((name) => (
              <span key={name}>
                <br />
                <Text strong>{name}</Text>
              </span>
            ))}
          </p>
          {assignedTemplates.length !== selectedKeys.length && (
            <p>Are you sure you want to delete other selected templates?</p>
          )}
        </>
      );
    }

    setConfirmMessage(message);
    setVisibleConfirmation(true);
    setIsAllSelectedAssigned(assignedTemplates.length === selectedKeys.length);
  };

  const handleConfirmDeletion = () => {
    const unassignedTemplatesIds = selectedKeys.reduce((acc, templateKey) => {
      const rowData = data.find(({ key }) => templateKey === key);

      return rowData && !rowData.template.assigned ? [...acc, +templateKey.split('-')[0]] : acc;
    }, []);

    dispatch(
      removeTemplate({
        ids: unassignedTemplatesIds,
        successCallback: () => clearSelectedRows(),
      })
    );
  };

  const resetConfirmationModal = () => {
    setIsAllSelectedAssigned(false);
    setVisibleConfirmation(false);
  };

  useEffect(() => {
    if (!removeLoading) {
      resetConfirmationModal();
    }
  }, [removeLoading]);

  useEffect(() => {
    if (importValidationData.length) {
      setAssignTemplateUpload(false);
      setOpenUpload(false);
      setTemplateMaintenanceUpload(false);
    }
  }, [importValidationData]);

  const handleCancel = () => {
    setAssignTemplateUpload(false);
    setOpenUpload(false);
    setTemplateMaintenanceUpload(false);
  };
  useEffect(() => {
    if (openUpload) {
      setType('Delete Ranges');
      setTableId('templateRanges');
    } else if (assignTemplateUpload) {
      setType('Assign Templates');
      setTableId('assignTemplates');
    } else if (templateMaintenanceUpload) {
      setType('Template Maintenance');
      setTableId('templateMaintenance');
    }
  }, [openUpload, assignTemplateUpload, templateMaintenanceUpload]);
  return (
    <TemplatesButtonsWrap>
      <ActionButton type={'ghost'} style={{ display: 'none' }}>
        NEW TEMPLATE
      </ActionButton>
      <ActionButton type={'ghost'} onClick={() => removeItems()}>
        DELETE TEMPLATE
      </ActionButton>
      <ActionButton type={'ghost'} style={{ display: 'none' }} disabled>
        COPY TEMPLATE
      </ActionButton>
      <ActionButton type={'ghost'} onClick={() => setOpenUpload(true)}>
        DELETE RANGES
      </ActionButton>
      <ActionButton type={'ghost'} onClick={() => setAssignTemplateUpload(true)}>
        ASSIGN TEMPLATES
      </ActionButton>
      <ActionButton type={'ghost'} onClick={() => setTemplateMaintenanceUpload(true)}>
        TEMPLATE MAINTENANCE
      </ActionButton>

      <StyledConfirmModal
        title={
          <>
            <ExclamationCircleOutlined />{' '}
            <span className={'confirm-title'}>{isAllSelectedAssigned ? 'Warning' : 'Confirmation'}</span>
          </>
        }
        visible={visibleConfirmation}
        destroyOnClose
        centered
        footer={
          <>
            <Button
              type={'default'}
              onClick={() => {
                resetConfirmationModal();
              }}
            >
              {isAllSelectedAssigned ? 'Cancel' : 'NO'}
            </Button>
            {!isAllSelectedAssigned && (
              <Button
                type={'primary'}
                loading={removeLoading}
                onClick={() => {
                  handleConfirmDeletion();
                }}
              >
                YES
              </Button>
            )}
          </>
        }
      >
        <span>{confirmMessage}</span>
      </StyledConfirmModal>
      <UploadModal
        visible={openUpload ? openUpload : assignTemplateUpload ? assignTemplateUpload : templateMaintenanceUpload}
        data={importValidationData}
        onCancel={handleCancel}
        type={type}
      />
      <ValidationModal
        visible={!!importValidationData.length}
        data={importValidationData}
        type={type}
        tableId={tableId}
      />
    </TemplatesButtonsWrap>
  );
};

const TemplatesButtonsWrap = styled.div`
  display: flex;
  flex-grow: 1;
`;

const ActionButton = styled(Button)`
  margin-left: 15px;
`;

const StyledConfirmModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
    border-radius: 2px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      padding: 16px 24px;
      background: #fff;
      border-bottom: 1px solid #f0f0f0;
      border-radius: 2px;

      .ant-modal-title {
        font-size: 15px;
        color: #ff991f;

        .confirm-title {
          color: #000;
          margin-left: 5px;
        }
      }
    }
  }
`;
