import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Empty, Spin } from 'antd';
import { isEmpty, isEqual, isNil, isNull, merge, uniq } from 'lodash';

import { getDayIndex, getMonth, getMonthRangeValues, getYear } from '../../../../common/helpers/date';
import {
  assignTemplateFailed,
  bulkAssignTemplate,
  fetchAllTemplates,
  fetchDoctorAvailability,
  fetchDoctorAvailabilitySuccess,
  fetchDoctors,
  fetchDoctorsHooChanged,
  fetchSelectedDoctor,
  setSelectedDoctor,
  setSelectedRow,
} from '../../../../pace/pace-actions';
import {
  getDoctorAvailability,
  getErrorAssign,
  getIsDoctorLoading,
  getIsHooChangedAction,
  getSuccessAssign,
  getTemplates,
} from '../../../../pace/pace-selectors';
import { useFirstRender } from '../../../../common/hooks/use-first-render';

import { HourPatternCell } from '../../cell-components/HourPatternCell';
import WeekGrid from './WeekGrid';
import {
  StyledAssignButton,
  StyledCalendar,
  StyledCheckbox,
  StyledDrawer,
  StyledMonthSelect,
  StyledWeekWrapper,
} from '../styled/styled';
import { useDeepEffect } from '../../../../common/hooks/use-deep-effect';
import { unmappOffice } from '../../../templates/templates-actions';

const { Text } = Typography;

interface ISchedule {
  start: string;
  end: string;
}

interface IDayOfWeek {
  value: number;
  key: number;
  children: string;
  schedule: ISchedule;
}

interface IEmployeeData {
  [key: string]: Partial<IDayOfWeek>;
}

export interface IMonthRange {
  label: string;
  value: string;
}

const EmployeePattern = ({ doctor, doctorInfo, search }) => {
  const doctorWithSortedOffices = useMemo(
    () => ({
      ...doctor,
      offices: doctor?.offices?.reduce(
        (acc, office) => (office?.office === doctorInfo?.office ? [office, ...acc] : [...acc, office]),
        []
      ),
    }),
    [doctor]
  );
  const dispatch = useDispatch();

  const monthRanges: IMonthRange[] = getMonthRangeValues();
  const { base, startDate } = search;

  const firstRender = useFirstRender();
  const doctorAvailability = useSelector(getDoctorAvailability);
  const templates = useSelector(getTemplates);
  const errorAssign = useSelector(getErrorAssign);
  const successAssign = useSelector(getSuccessAssign);
  const loadingDoctor = useSelector(getIsDoctorLoading);
  const isHooChangedAction = useSelector(getIsHooChangedAction);

  const [isAssignDisabled, setIsAssignDisabled] = useState<boolean>(true);
  const [initialDocTemplates, setInitialDocTemplates] = useState<IEmployeeData | null>(null);
  const [suggestedOptions, setSuggestedOptions] = useState<IEmployeeData | null>(null);
  const [isWholeWeek, setIsWholeWeek] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<IEmployeeData | null>(null);
  const [locationInEditMode, setLocationInEditMode] = useState<string | null>(null);
  const [isSuggestedOptions, setIsSuggestedOptions] = useState<boolean>(false);
  const [isOfficesTouched, setIsOfficesTouched] = useState<string[]>([]);
  const [isOfficesDaysTouched, setIsOfficesDaysTouched] = useState<{ [key: string]: number[] }>({});

  const [rangeValue, setRangeValue] = useState<IMonthRange>(monthRanges[0]);
  const [rangeValues, setRangeValues] = useState<IMonthRange[]>([]);
  const [unmapDetails, setUnmapDetails] = useState<any>({});

  useEffect(() => {
    setRangeValues(monthRanges);
  }, []);

  useEffect(() => {
    if (doctorAvailability) {
      const data = doctorAvailability.data.map(({ empty: disabled }) => ({ disabled }));
      const updatedData = merge(rangeValues, data);
      setRangeValues(updatedData);
    }
  }, [doctorAvailability]);

  useEffect(() => {
    if (!firstRender) {
      dispatch(
        fetchSelectedDoctor({
          id: doctorInfo.employeeId,
          office: doctorInfo.office,
          base,
          startDate: rangeValue?.value,
        })
      );
      setLocationInEditMode(doctorInfo.office);
    }
  }, [rangeValue]);

  useEffect(() => {
    setIsAssignDisabled(isNull(errorAssign));
  }, [errorAssign]);

  useEffect(() => {
    if (successAssign) {
      setLocationInEditMode(null);
      setInitialDocTemplates(selectedOptions);
      if (base) {
        fetchAvailability();
      }
    }
  }, [successAssign]);

  useDeepEffect(() => {
    if (isNil(selectedOptions) && !isEmpty(doctor) && !isEmpty(doctor?.weeks?.[0])) {
      const week = doctor.weeks[0];
      const getAssigned = (dayIdx, office) => week?.[dayIdx]?.offices?.[office]?.assignedTemplate;
      const getSuggested = (dayIdx, office) => week?.[dayIdx]?.offices?.[office]?.suggestedTemplate;
      const areSuggestedTemplates = week?.some(({ offices }) => offices?.[doctorInfo?.office]?.suggestedTemplate?.id);

      setLocationInEditMode(doctorInfo.office);

      const getTemplate = (data, isSuggested = false) => {
        if (isSuggested) {
          setIsSuggestedOptions(isSuggested);
          setIsOfficesTouched((prev) => uniq([...prev, doctorInfo.office]));
        }

        return {
          key: data?.id?.toString(),
          value: data?.id,
          children: data?.name,
          schedule: {
            start: data?.start,
            end: data?.end,
          },
          ...(isSuggested ? { suggested: true } : {}),
        };
      };

      const getTemplates = (office) =>
        week?.reduce((acc, { offices, dayOfWeek }, i) => {
          const assigned = getAssigned(i, office);
          const suggested = getSuggested(i, office);
          const template = assigned?.id ? getTemplate(assigned) : suggested ? getTemplate(suggested, true) : {};

          return {
            ...acc,
            ...(!isEmpty(template) && { [getDayIndex(dayOfWeek)]: template }),
          };
        }, {});

      const getOffices = (acc, { office }) => ({
        ...acc,
        [office]: getTemplates(office),
      });

      const templates = doctor?.offices?.reduce(getOffices, {});

      const filterTemplates = (data, option: 'assigned' | 'suggested', excludedOffice?) =>
        Object.fromEntries(
          Object.entries(data).map(([office, templatesByDay]) => [
            office,
            excludedOffice && excludedOffice === office
              ? templatesByDay
              : Object.fromEntries(
                  Object.entries(templatesByDay)?.filter(([_, template]) => {
                    switch (option) {
                      case 'assigned':
                        return !template.suggested;
                      case 'suggested':
                        return template.suggested;
                      default:
                        return true;
                    }
                  })
                ),
          ])
        );

      dispatch(fetchAllTemplates());
      setSelectedOptions(filterTemplates(templates, 'assigned', doctorInfo?.office));
      setInitialDocTemplates(filterTemplates(templates, 'assigned'));
      setSuggestedOptions(filterTemplates(templates, 'suggested'));

      if (areSuggestedTemplates) {
        setIsAssignDisabled(false);
      }
    }
  }, [doctor]);

  const isCheckboxDisabled = () => {
    let isDisabled = true;

    if (locationInEditMode && !isNil(selectedOptions)) {
      const selectedOffice = selectedOptions[locationInEditMode];

      isDisabled =
        isEmpty(selectedOffice) ||
        !Object.values(selectedOffice)
          .filter((e) => !isEmpty(e))
          .every((elem: any, i, arr: any) => {
            let templateName = arr[0].children.split(' ')[1];

            return elem.children.split(' ')[1] === templateName;
          });

      if (isDisabled && isWholeWeek) {
        setIsWholeWeek(false);
      }
    }

    return isDisabled;
  };

  const onDrawerClose = () => {
    dispatch(setSelectedRow(null));
    setInitialDocTemplates(null);
    setSelectedOptions(null);
    setIsWholeWeek(false);
    setIsAssignDisabled(true);
    setRangeValue(monthRanges[0]);
    setLocationInEditMode(null);
    dispatch(assignTemplateFailed(null));
    dispatch(setSelectedDoctor({}));
    dispatch(fetchDoctorAvailabilitySuccess(null));

    if (isHooChangedAction) {
      dispatch(fetchDoctorsHooChanged(search));
    } else {
      dispatch(fetchDoctors(search));
    }
  };

  const getTemplateNameByOptions = (selectedOptions, dayOfWeek) => {
    let templateName = Object.values(selectedOptions as object).filter((option) => !isEmpty(option))?.[0]?.children;
    let templateEnd = templateName?.substring(templateName?.indexOf(' ') + 1);
    let weekElement = doctor?.weeks?.[0][dayOfWeek === 0 ? 6 : dayOfWeek - 1];

    if (locationInEditMode && weekElement?.offices?.[locationInEditMode]) {
      let templateComputed =
        weekElement?.offices?.[locationInEditMode]?.officeSchedule?.start.split(':')[0] +
        '-' +
        weekElement?.offices?.[locationInEditMode]?.officeSchedule?.end.split(':')[0] +
        ' ' +
        templateEnd;
      let foundedTemplate = templates?.allTemplates?.find((template) => template?.templateName === templateComputed);
      return (
        foundedTemplate && {
          children: foundedTemplate?.templateName,
          key: `${foundedTemplate?.id}`,
          value: foundedTemplate?.id,
        }
      );
    }
  };

  const allWeekWithoutHOO = () => {
    return locationInEditMode && doctor?.weeks?.[0].every((e) => !e?.offices?.[locationInEditMode]?.officeSchedule);
  };

  const onCheckboxChange = (e) => {
    setIsWholeWeek(e.target.checked);

    if (e.target.checked) {
      if (!isEmpty(selectedOptions) && !!locationInEditMode) {
        setSelectedOptions((prev): IEmployeeData => {
          const selectedOffice = prev![locationInEditMode];

          const data = {
            ...[0, 1, 2, 3, 4, 5, 6].reduce((acc, dayOfWeek) => {
              const template =
                !isNil(selectedOffice) &&
                (allWeekWithoutHOO()
                  ? Object.values(selectedOffice)[0]
                  : getTemplateNameByOptions(selectedOffice, dayOfWeek));

              if (!isNil(selectedOffice) && template) {
                setIsOfficesDaysTouched((prev) => ({
                  ...prev,
                  ...{
                    [locationInEditMode]: prev[locationInEditMode]
                      ? uniq([...prev[locationInEditMode], dayOfWeek])
                      : [dayOfWeek],
                  },
                }));
              }

              return {
                ...acc,
                ...(template && { [dayOfWeek]: template }),
              };
            }, {}),
          };

          return { ...prev, [locationInEditMode]: data };
        });

        setIsAssignDisabled(false);
      }
    }
  };

  const onAssign = () => {
    setIsAssignDisabled(true);

    const data = Object.entries(selectedOptions as IEmployeeData)
      .filter(([officeNumber]) => isOfficesTouched.includes(officeNumber))
      .map(([office, officeData]) => {
        return {
          office,
          templateByDayDtoList: Object.entries(officeData as IDayOfWeek)
            .filter(([, value]) => !isEmpty(value))
            .map(([key, { value }]) => ({
              dayOfWeek: key,
              templateId: value,
            })),
        };
      });

    const payload = {
      employeeId: doctor?.employeeId,
      data,
    };

    dispatch(bulkAssignTemplate(payload));

    setIsOfficesTouched([]);
    setIsOfficesDaysTouched({});
  };

  const mergeAssignedAndSuggested = (office) =>
    Object.fromEntries(
      [0, 1, 2, 3, 4, 5, 6]
        .map((dayIdx) => [dayIdx, initialDocTemplates?.[office]?.[dayIdx] ?? suggestedOptions?.[office]?.[dayIdx]])
        .filter(([_, template]) => template)
    );

  const onEditLocation = (locationNumber) => {
    if (!locationNumber) {
      setSelectedOptions((prev) => ({
        ...prev,
        ...(locationInEditMode && initialDocTemplates
          ? { [locationInEditMode]: initialDocTemplates[locationInEditMode] }
          : {}),
      }));
    } else {
      setSelectedOptions((prev) => ({
        ...prev,
        [locationNumber]: mergeAssignedAndSuggested(locationNumber),
      }));
    }
    setLocationInEditMode(locationNumber);
  };

  const fetchAvailability = () => {
    const datesAvailability = monthRanges.map(({ value }) => ({
      month: getMonth(value),
      year: getYear(value),
    }));
    dispatch(
      fetchDoctorAvailability({
        employeeId: doctorInfo.employeeId,
        payload: { base, office: doctorInfo.office, dates: datesAvailability },
      })
    );
  };

  const handleUnmapOffices = () => {
    dispatch(unmappOffice(unmapDetails));
    onDrawerClose();
  };

  return (
    <StyledDrawer
      placement='right'
      visible={!isEmpty(doctor)}
      width={'100%'}
      closable={true}
      className='pace-drawer-panel'
      title={'Employee Week Pattern'}
      closeIcon={<img src={'./icons/back.svg'} alt='back-icon' />}
      onClose={onDrawerClose}
      afterVisibleChange={(isVisible) => isVisible && fetchAvailability()}
    >
      <div className='subheader'>
        <div className='subheader__text'>{`${doctorInfo?.name} ${doctorInfo.employeeId}`}</div>
        <StyledMonthSelect
          options={rangeValues}
          suffixIcon={<StyledCalendar src={'./icons/calendar.svg'} alt='calendar' />}
          value={rangeValue}
          onChange={(key, option) => setRangeValue(option)}
          dropdownStyle={stylesMonthSelect}
        />
      </div>
      <Spin spinning={loadingDoctor}>
        <div className='week'>
          {isNil(doctor.weeks) ? (
            <Empty />
          ) : (
            doctor.weeks.map((week, idx) => (
              <StyledWeekWrapper key={idx} isFirst={idx === 0}>
                {week && (
                  <div className='week__pattern-wrapper'>
                    <div className='subheader__office'>
                      <div>{locationInEditMode}</div>
                      <div>{doctor?.offices?.find(({ office }) => office === locationInEditMode)?.officeName}</div>
                    </div>
                    <div className='pattern-bar'>
                      <Text strong>Employee hour pattern</Text>
                      <HourPatternCell
                        pattern={{
                          hours: week.map(
                            ({ offices }) => offices?.[locationInEditMode]?.personalSchedule?.duration ?? 0
                          ),
                          averages: week?.[0]?.offices?.[locationInEditMode]?.averages,
                        }}
                      />
                    </div>
                    <div>
                      <Text strong>Office HOO</Text>
                      <HourPatternCell
                        pattern={{
                          hours: week.map(
                            ({ offices }) => offices?.[locationInEditMode]?.officeSchedule?.duration ?? 0
                          ),
                          averages: undefined,
                        }}
                      />
                    </div>
                  </div>
                )}
                <WeekGrid
                  doctor={doctorWithSortedOffices}
                  week={week}
                  idx={idx}
                  startDate={rangeValue.value || startDate}
                  isSuggestedOptions={isSuggestedOptions}
                  isOfficesTouched={isOfficesTouched}
                  setIsOfficesTouched={setIsOfficesTouched}
                  getTemplateNameByOptions={getTemplateNameByOptions}
                  isWholeWeek={isWholeWeek}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  setIsAssignDisabled={setIsAssignDisabled}
                  locationInEditMode={locationInEditMode}
                  onEditLocation={onEditLocation}
                  setIsOfficesDaysTouched={setIsOfficesDaysTouched}
                  isOfficesDaysTouched={isOfficesDaysTouched}
                  unmapDetails={unmapDetails}
                  setUnmapDetails={setUnmapDetails}
                  handleUnmapOffices={handleUnmapOffices}
                />
                <div className='week__assign-btn-wrap'>
                  {idx === 0 && (
                    <>
                      <StyledCheckbox
                        className='week__assign-checkbox'
                        onChange={onCheckboxChange}
                        checked={isWholeWeek}
                        disabled={isCheckboxDisabled()}
                      >
                        Same template to whole week
                      </StyledCheckbox>
                      <StyledAssignButton
                        disabled={isEqual(selectedOptions, initialDocTemplates) || isAssignDisabled}
                        type='primary'
                        onClick={onAssign}
                      >
                        Assign
                      </StyledAssignButton>
                    </>
                  )}
                </div>
              </StyledWeekWrapper>
            ))
          )}
        </div>
      </Spin>
    </StyledDrawer>
  );
};

const stylesMonthSelect = {
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '14px',
};

export default EmployeePattern;
