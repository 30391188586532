import * as Eff from 'redux-saga/effects';
import { EXPORT_FILE } from '../components/header/header-actions';
import { exportFileStartWorker } from '../components/header/header-sagas';
import { FETCH_AUDIT } from '../components/audit/audit-actions';
import { fetchAuditStartWorker } from '../components/audit/audit-sagas';
import {
  ASSIGN_TEMPLATE,
  ASSIGN_TEMPLATE_SPECIAL,
  BULK_ASSIGN_ACTIVITY,
  BULK_ASSIGN_TEMPLATE,
  BULK_ASSIGN_TEMPLATE_SPECIAL,
  FETCH_ACTIVITIES,
  FETCH_ALL_TEMPLATES,
  FETCH_DISTRICTS,
  FETCH_DOCTOR_AVAILABILITY,
  FETCH_DOCTORS,
  FETCH_DOCTORS_HOO_CHANGED,
  FETCH_DOCTORS_SPECIAL,
  FETCH_OD_DATA_INCOMPLETE,
  FETCH_REGIONS,
  FETCH_SELECTED_DOCTOR,
  FETCH_SELECTED_DOCTOR_SPECIAL,
  FETCH_SELECTED_DOCTORS_SPECIAL,
  FETCH_SELECTED_DOCTORS_SPECIAL_ALL,
  FETCH_TEMPLATES,
} from '../pace/pace-actions';
import {
  assignTemplateStartWorker,
  fetchDoctorsSpecialStartWorker,
  fetchDoctorsStartWorker,
  fetchSelectedDoctorStartWorker,
  fetchTemplatesStartWorker,
  fetchAllTemplatesStartWorker,
  fetchODDataIncompleteStartWorker,
  fetchSelectedDoctorSpecialStartWorker,
  assignTemplateSpecialStartWorker,
  fetchDoctorAvailabilityStartWorker,
  bulkAssignTemplateSpecialWorker,
  bulkAssignTemplateStartWorker,
  fetchRegionsStartWorker,
  fetchDistrictsStartWorker,
  fetchSelectedDoctorsSpecialStartWorker,
  fetchSelectedDoctorsSpecialAllStartWorker,
  bulkAssignActivityStartWorker,
  fetchActivitiesStartWorker,
  fetchDoctorsHooChangedStartWorker,
} from '../pace/pace-sagas';
import {
  FETCH_TEMPLATE_DETAILS,
  IMPORT_FILE,
  REMOVE_TEMPLATES,
  RENAME_TEMPLATE,
  SEARCH_TEMPLATES,
  UNMAP_OFFICE,
  UPDATE_VALIDATED_IMPORT,
} from '../components/templates/templates-actions';
import {
  fetchTemplateDetailsStartWorker,
  removeTemplateStartWorker,
  searchTemplatesStartWorker,
  renameTemplateNameStartWorker,
  importBulkUpdateFileStartWorker,
  updateValidatedImportStartWorker,
  unmapingOffice,
} from '../components/templates/templates-sagas';
import { fetchNewOfficesStartWorker } from '../pace/new-offices/new-offices-sagas';
import { FETCH_NEW_OFFICES } from '../pace/new-offices/new-offices-actions';
import { exportReportStartWorker } from '../components/reports/reports-sagas';
import { EXPORT_REPORT } from '../components/reports/reports-actions';
import { FETCH_PATTERNS, FETCH_RAMP_SETTINGS, UPDATE_RAMP_SETTINGS } from './common/common-actions';
import {
  fetchPatternsStartWorker,
  fetchRampSettingsStartWorker,
  updateRampSettingsStartWorker,
} from './common/common-sagas';
import {
  CREATE_EXCEPTION,
  FETCH_EMPLOYEES,
  FETCH_EXCEPTION_DETAILS,
  FETCH_OFFICES,
  SEARCH_EXCEPTION,
} from '../components/ExceptiopnManagement/actions';
import {
  createExceptionStartWorker,
  fetchEmployeesStartWorker,
  fetchExceptionDetailsStartWorker,
  fetchOfficesStartWorker,
  searchExceptionStartWorker,
} from '../components/ExceptiopnManagement/sagas';

const takeEvery: any = Eff.takeEvery;

export default function* rootSaga() {
  yield takeEvery(FETCH_AUDIT, fetchAuditStartWorker);
  yield takeEvery(EXPORT_FILE, exportFileStartWorker);
  yield takeEvery(FETCH_DOCTORS, fetchDoctorsStartWorker);
  yield takeEvery(FETCH_DOCTORS_SPECIAL, fetchDoctorsSpecialStartWorker);
  yield takeEvery(FETCH_OD_DATA_INCOMPLETE, fetchODDataIncompleteStartWorker);
  yield takeEvery(FETCH_SELECTED_DOCTOR, fetchSelectedDoctorStartWorker);
  yield takeEvery(FETCH_SELECTED_DOCTOR_SPECIAL, fetchSelectedDoctorSpecialStartWorker);
  yield takeEvery(FETCH_SELECTED_DOCTORS_SPECIAL, fetchSelectedDoctorsSpecialStartWorker);
  yield takeEvery(FETCH_SELECTED_DOCTORS_SPECIAL_ALL, fetchSelectedDoctorsSpecialAllStartWorker);
  yield takeEvery(FETCH_TEMPLATES, fetchTemplatesStartWorker);
  yield takeEvery(FETCH_ALL_TEMPLATES, fetchAllTemplatesStartWorker);
  yield takeEvery(ASSIGN_TEMPLATE, assignTemplateStartWorker);
  yield takeEvery(ASSIGN_TEMPLATE_SPECIAL, assignTemplateSpecialStartWorker);
  yield takeEvery(FETCH_DOCTOR_AVAILABILITY, fetchDoctorAvailabilityStartWorker);
  yield takeEvery(BULK_ASSIGN_TEMPLATE_SPECIAL, bulkAssignTemplateSpecialWorker);
  yield takeEvery(BULK_ASSIGN_TEMPLATE, bulkAssignTemplateStartWorker);
  yield takeEvery(FETCH_REGIONS, fetchRegionsStartWorker);
  yield takeEvery(FETCH_DISTRICTS, fetchDistrictsStartWorker);
  yield takeEvery(SEARCH_TEMPLATES, searchTemplatesStartWorker);
  yield takeEvery(FETCH_TEMPLATE_DETAILS, fetchTemplateDetailsStartWorker);
  yield takeEvery(REMOVE_TEMPLATES, removeTemplateStartWorker);
  yield takeEvery(FETCH_NEW_OFFICES, fetchNewOfficesStartWorker);
  yield takeEvery(RENAME_TEMPLATE, renameTemplateNameStartWorker);
  yield takeEvery(EXPORT_REPORT, exportReportStartWorker);
  yield takeEvery(FETCH_RAMP_SETTINGS, fetchRampSettingsStartWorker);
  yield takeEvery(UPDATE_RAMP_SETTINGS, updateRampSettingsStartWorker);
  yield takeEvery(FETCH_PATTERNS, fetchPatternsStartWorker);
  yield takeEvery(FETCH_ACTIVITIES, fetchActivitiesStartWorker);
  yield takeEvery(BULK_ASSIGN_ACTIVITY, bulkAssignActivityStartWorker);
  yield takeEvery(FETCH_DOCTORS_HOO_CHANGED, fetchDoctorsHooChangedStartWorker);
  yield takeEvery(IMPORT_FILE, importBulkUpdateFileStartWorker);
  yield takeEvery(UPDATE_VALIDATED_IMPORT, updateValidatedImportStartWorker);
  yield takeEvery(SEARCH_EXCEPTION, searchExceptionStartWorker);
  yield takeEvery(FETCH_EXCEPTION_DETAILS, fetchExceptionDetailsStartWorker);
  yield takeEvery(CREATE_EXCEPTION, createExceptionStartWorker);
  yield takeEvery(FETCH_OFFICES, fetchOfficesStartWorker);
  yield takeEvery(FETCH_EMPLOYEES, fetchEmployeesStartWorker);
  yield takeEvery(UNMAP_OFFICE, unmapingOffice);
}
