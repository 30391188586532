import {
  SET_SEARCH_TEMPLATES,
  SEARCH_TEMPLATES,
  SEARCH_TEMPLATES_SUCCESS,
  SEARCH_TEMPLATES_FAILED,
  SET_SORT_CRITERIAS,
  FETCH_TEMPLATE_DETAILS,
  FETCH_TEMPLATE_DETAILS_SUCCESS,
  FETCH_TEMPLATE_DETAILS_FAILED,
  SET_EDITED_EXAM,
  REMOVE_TEMPLATES,
  REMOVE_TEMPLATES_SUCCESS,
  REMOVE_TEMPLATES_FAILED,
  RENAME_TEMPLATE_FAILED,
  RENAME_TEMPLATE_SUCCESS,
  RENAME_TEMPLATE,
  IMPORT_FILE,
  IMPORT_FILE_FAILED,
  IMPORT_FILE_SUCCESS,
  CLEAN_IMPORT_LAYOUT,
  UPDATE_VALIDATED_IMPORT,
  UPDATE_VALIDATED_IMPORT_SUCCESS,
  UPDATE_VALIDATED_IMPORT_FAILED,
  UNMAP_OFFICE,
  UNMAP_OFFICE_SUCCESS,
  UNMAP_OFFICE_FAILED,
} from './templates-actions';

type TState = {
  templates: {
    contents: [];
    pages: number;
    total: number;
  };
  search: {
    page: number;
    size: number;
    assigned: boolean;
    templateName?: string;
    pace?: {
      from: number;
      to: number;
    };
    shiftLength?: {
      from: number;
      to: number;
    };
    examShiftLength?: {
      from: number;
      to: number;
    };
  };
  sorting: {};
  loading: boolean;
  error: any;
  templateDetails: any;
  detailsLoading: boolean;
  removeLoading: boolean;
  editedExam: any;
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
  totalCount: any;
  successErrorReport: any;
};

const initialState: TState = {
  templates: {
    contents: [],
    pages: 0,
    total: 0,
  },
  search: {
    page: 0,
    size: 15,
    assigned: false,
  },
  sorting: {},
  loading: false,
  error: null,
  templateDetails: null,
  detailsLoading: false,
  removeLoading: false,
  editedExam: null,
  importLoading: false,
  importFailed: false,
  importValidationData: [],
  totalCount: null,
  successErrorReport: {},
};

export const templatesManagementReducer = (state = initialState, { type, payload, totalCount }) => {
  switch (type) {
    case SET_SEARCH_TEMPLATES:
      return {
        ...state,
        search: payload,
      };
    case SEARCH_TEMPLATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEARCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: payload,
      };
    case FETCH_TEMPLATE_DETAILS_FAILED:
    case SEARCH_TEMPLATES_FAILED:
    case REMOVE_TEMPLATES_FAILED:
    case RENAME_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        removeLoading: false,
        detailsLoading: false,
        error: payload,
      };
    case SET_SORT_CRITERIAS:
      return {
        ...state,
        sorting: payload,
      };
    case FETCH_TEMPLATE_DETAILS:
      return {
        ...state,
        detailsLoading: true,
      };
    case FETCH_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        templateDetails: payload,
      };
    case SET_EDITED_EXAM:
      return {
        ...state,
        editedExam: payload,
      };
    case REMOVE_TEMPLATES:
      return {
        ...state,
        removeLoading: true,
      };
    case REMOVE_TEMPLATES_SUCCESS:
      return {
        ...state,
        removeLoading: false,
      };
    case RENAME_TEMPLATE:
      return {
        ...state,
        detailsLoading: true,
      };
    case RENAME_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateDetails: { ...state.templateDetails, templateName: payload },
        detailsLoading: false,
      };
    case IMPORT_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
        totalCount: totalCount,
      };
    }
    case IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT_SUCCESS: {
      return {
        ...state,
        successErrorReport: payload,
      };
    }
    case UPDATE_VALIDATED_IMPORT_FAILED: {
      return {
        ...state,
        importLoading: false,
      };
    }
    case CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: [],
        successErrorReport: {},
      };
    }
    case UNMAP_OFFICE: {
      return {
        ...state,
      };
    }
    case UNMAP_OFFICE_SUCCESS: {
      return {
        ...state,
      };
    }

    case UNMAP_OFFICE_FAILED: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
